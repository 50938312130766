import React from 'react';
import { Box } from '@mui/material';
import { StandardLayout } from '../layouts';

const IndexPage = () => (
  <StandardLayout>
    <Box p={4}>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Box>
  </StandardLayout>
);

export default IndexPage;
